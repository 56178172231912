.mainDiv{
    background-color: #FFFFFF;
    display: flex;
}

.iconDashboard, .iconPaciente, .iconRecepcionista, .iconProfissionalSaude, .iconQuestionario{
    margin: 05px;
}

.navDashboard{
    display: flex;
    justify-content: center;
    margin-left: 15px;  
    color: #828282; 
    font-weight: 600;
}

.navPaciente{
    color: #828282; 
    font-weight: 600;
}

.navRecepcionista{
    color: #828282; 
    font-weight: 600;
}

.navProfissionalSaude{
    color: #828282; 
    font-weight: 600;
}

.navListaQuestionario{
    color: #828282; 
    font-weight: 600;
}

.navDashboard:hover, .navPaciente:hover, .navRecepcionista:hover, .navProfissionalSaude:hover, .navListaQuestionario:hover{
    color: #1C2D5D
}

.navDashboard:focus, .navPaciente:focus, .navRecepcionista:focus, .navProfissionalSaude:focus, .navListaQuestionario:focus{
    color: #1C2D5D
}

.itensDropdown{
    color: #828282  
}

