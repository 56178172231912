.mainDiv{
    height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
} 

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #BDBDBD;
}

.table{
    margin: auto;
    color: #4F4F4F;
    font-weight: 600;
    
}

.tbody{
    color: #4F4F4F !important;
}

.title{
    font-weight: 600;
    color: #1C2D5D;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: larger;
}

.route{
    color: #4F4F4F; 
    padding-top: 20px;
    padding-left: 30px;
    font-size: small;
}

.divInput{
    align-self: flex-start;
    width: 500px;
    height: max-content;
    padding-bottom: 30px;
}

.inputBusca{
    font-size: small;
}

.inputBusca::placeholder{
    font-size: small;
}


.textBuscar{
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
}

.iconBuscar{
    margin-right: 10px;
}

.buttonIcon{
    margin-right: 10px;
}

.buttonPerfil{
    font-weight: 600;
    border-radius: 5px;
    background-color: #208776;;
    font-size: small;
}

.buttonPerfil:hover{
    font-weight: 600;
    border-radius: 5px;
    background-color: #196758;;
    font-size: small;
}

.buttonFormulario{
    font-weight: 600;
    color:#208776; 
    border-radius: 5px;
    background-color: #FFFFFF;;
    font-size: small;
}

.buttonFormulario:hover{
    font-weight: 600;
    color:#208776; 
    border-radius: 5px;
    background-color: #B4DED8;;
    font-size: small;
}

.selectFiltro{
    width: max-content;
    font-size: small;
}

.textFiltro{
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
}
